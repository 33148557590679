
import React, { Component } from 'react'
import { connect } from 'react-redux'
import 'styles/newflash/newflash.scss'
import Subscribe from 'comps/subscribe/Subscribe'
import HotNews from 'comps/information/HotNews'
import Auther from 'comps/Auther'
// import axios from 'axios'
import moment from 'moment'
import fromatTime from 'utils/formatTime'
interface stateType{
    req:{
        page:number,
        size:number 
    },
    flashList: any[],
    total:number,
    preFlashList: any[]
}
interface propsType{
    location: {
        state: any[]
    }
}
class Newsflash extends Component<propsType, stateType> {
    constructor(props:propsType) {
        super(props)
        this.state = {
            req:{
                page:1,
                size:10
            },
            flashList:[],
            total:0,
            preFlashList: []
        }

    }
    
    componentDidMount(){
        this.getDefaultflashList()
    }

    //快讯列表
    getflashList = () => {
        let req =  this.state.req
        this.axios.post('/flash',req).then(res => {
            let preFlashList = this.state.preFlashList
            if (res.code === 10000){
                preFlashList.push(...res.data.items)
                let data = this.formatData(preFlashList, 'date')
                this.setState({
                    flashList:data,
                    total: res.data.count,
                    preFlashList
                }, () => {
                    // console.log('preFlashList', this.state.preFlashList)
                    // console.log('flashList', this.state.flashList)

                    //滚动条定位到指定位置
                    // let id = this.props.location.state && this.props.location.state[0].id
                    // this.props.location.state && document.getElementById(id) && document.getElementById(id).scrollIntoView(true)
                })
            }
        })
    }
      //快讯列表
      getDefaultflashList = () => {
          let req =  this.state.req
          this.axios.post('/flash',req).then(res => {
              let preFlashList = this.state.preFlashList
              if (res.code === 10000){
                  preFlashList.push(...res.data.items)
                  let data = this.formatData(preFlashList, 'date')
                  this.setState({
                      flashList:data,
                      total: res.data.count,
                      preFlashList
                  }, () => {
                      // console.log('preFlashList', this.state.preFlashList)
                      // console.log('flashList', this.state.flashList)

                      //滚动条定位到指定位置
                      let id = this.props.location.state && this.props.location.state[0].id
                      this.props.location.state && document.getElementById(id) && document.getElementById(id).scrollIntoView(true)
                  })
              }
          })
      }

    //按照日期分类
    formatData = (data, attribute) => {
        data.forEach(item => {
            item.date = moment(item.push_time * 1000).format('MM/DD YYYY')
        })
        const arr = []
        const obj = {}
        data.forEach(element => {
            if (!obj[element[attribute]]) {
                arr.push({
                    date: element[attribute],
                    allData: [element],
                })
                obj[element[attribute]] = element
            } else {
                arr.forEach(ele => {
                    if (ele.date === element[attribute]) {
                        ele.allData.push(element)
                    }
                })
            }
        })
        return arr
    }

    //加载更多
    loadMore = () => {
        let page = this.state.req.page
        page ++
        let req = Object.assign({}, this.state.req, { page })
        this.setState({
            req
        }, () => {
            this.getflashList()
        })
    }
    render() {
        const { flashList, preFlashList, total } = this.state
        const { getBannerList } = this.props
        return (
            <div className="newsflash-con">
                {
                    getBannerList.top ? getBannerList.top && getBannerList.top.url ? <a href={getBannerList.top.url}><img src={getBannerList.top && getBannerList.top.img} alt="" className="advertisement"/></a> : <img src={getBannerList.top && getBannerList.top.img} alt="" className="advertisement"/> : null
                }
                {/* <a href={getBannerList[0].url}><img src={getBannerList[0].img} alt="" className="advertisement"/></a> */}
                <div className="content">
                    <div className="flashlist">
                        {
                            flashList.map(item => {
                                return <>
                                    <div className="date">{item.date}</div>
                                    {
                                        item.allData.map((item, ind) => {
                                            return <div className="flashlist-item" id={'item' + item.id} key={ind}>
                                                <h1 title={item.name}>{item.name}</h1>
                                                <div className="intro" dangerouslySetInnerHTML={{ __html: item.content }} ></div>
                                                <div className="time-share">
                                                    <div className="time">{fromatTime(item.push_time * 1000)}</div>
                                                    {/* <div className="share-box">
                                                        <span>分享到：</span>
                                                        <img src={require('assets/images/weixin.svg')} alt="" className="share-weixin"/>
                                                        <img src={require('assets/images/weibo.svg')} alt="" className="share-weibo"/>
                                                    </div> */}
                                                </div>
                                            </div>
                                        })
                                    }
                                
                                </>
                            })
                        }
                        {total > preFlashList.length ? <div className="loadMore" onClick={this.loadMore}>加载更多</div> : null}
                    </div>
                    <div className="content-right">
                        <Subscribe></Subscribe>
                        <div className="little-title">本周热文</div>
                        <div className="newlist">
                            <HotNews></HotNews>
                        </div>
                        <div className="title">
                            <span className="little-title"><span>我们的团队</span><a href="/about">查看更多</a> </span>  
                        </div>
                        <Auther ></Auther>
                        <div className="littleimg">
                            {
                                getBannerList.sidebar ? getBannerList.sidebar && getBannerList.sidebar.url ? <a href={getBannerList.sidebar.url}><img src={getBannerList.sidebar && getBannerList.sidebar.img} alt=""/></a> : <img src={getBannerList.sidebar && getBannerList.sidebar.img} alt="" /> : null
                            }
                            {/* <a href={getBannerList[2].url}><img src={getBannerList[2].img} alt=""/></a> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        getBannerList: state.getBannerList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Newsflash)

