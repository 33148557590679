// 新增 mit APP分享页面
import ArticleShare from 'pages/h5share/ArticleShare'
import VideoShare from 'pages/h5share/VideoShare'
import FlashShare from 'pages/h5share/FlashShare'
import Privacy from 'pages/h5agree/Privacy'
import Question from 'pages/h5agree/Question'
import UserAgreement from 'pages/h5agree/UserAgreement'
import ContactUs from 'pages/h5agree/ContactUs'
import AboutUs from 'pages/h5agree/AboutUs'
import { Error500,Error404,Error403 } from 'pages/error'

const PublicRoutes = [
    { path: '/app/privacy', component: Privacy }, // APP隐私条框
    { path: '/app/question', component: Question }, // APP常见问题
    { path: '/app/useragree', component: UserAgreement }, // APP用户协议
    { path: '/app/contactus', component: ContactUs }, // APP联系我们
    { path: '/app/aboutus', component: AboutUs }, // APP关于我们
    { path: '/share/article/:id', component: ArticleShare }, // APP 分享文章详情
    { path: '/share/flash/:id', component: FlashShare }, // APP 分享的快讯详情
    { path: '/share/video/:id', component: VideoShare }, // APP 分享的视频详情
    { path: '/articleshare/:id', component: ArticleShare },
    { path: '/flashshare/:id', component: FlashShare },
    { path: '/videoshare/:id', component: VideoShare },
    {
        path:'/error500',
        component:Error500,
        exact:true
    },{
        path:'/error404',
        component:Error404,
        exact:true
    },{ 
        path:'/error403',
        component:Error403,
        exact:true
    }
]

export default PublicRoutes