
import React, { Component } from 'react'
import 'styles/components/auther.scss'
import Avatar from './Avatar'
interface stateType{
    auther: any[]
}
interface propsType{
    autherData: object,
    pageSize: Number
}
class Auther extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            auther: []
        }

    }
    
    componentDidMount(){
        this.getAuther()
    }

    
    //作者
    getAuther = () => {
        this.axios.get('/author/rand').then(res => {
            if (res.code === 10000){
                let auther = []
                if (this.props.pageSize) {
                    auther = res.data.splice(0, 2)
                } else {
                    auther = res.data
                }
                this.setState({
                    auther
                })
            }
        })
    }
    render() {
        const { auther  } = this.state
        const { autherData } = this.props
        return (
            <div>
                {
                    autherData ?  ( <a href={`/author/detail/${autherData.id}`}>
                        <dl className="auther">
                            <dt><Avatar photoUrl={autherData.avatar} photoWidth={'80px'} photoHeight={'80px'} isRadius={true}></Avatar></dt>
                            <dd>
                                <div className="title">{ autherData.username || autherData.nick_name}</div>
                                <div className="intro" title={autherData.summary}>{ autherData.summary }</div>
                            </dd>
                        </dl>
                    </a>) :
                        auther && auther.map(item => {
                            return <a href={`/author/detail/${item.id}`}>
                                <dl className="auther">
                                    <dt><Avatar photoUrl={item.avatar} photoWidth={'80px'} photoHeight={'80px'} isRadius={true}></Avatar></dt>
                                    <dd>
                                        <div className="title">{ item.username || item.nick_name}</div>
                                        <div className="intro" title={item.summary }>{ item.summary }</div>
                                    </dd>
                                </dl>
                            </a>
                        })
                }
            </div>
        )
    }
}
export default Auther

