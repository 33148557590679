import React,{ useEffect, useState } from 'react'
import FooterShare from './FooterShare'
import 'styles/h5share/share.scss'
import { Skeleton } from 'antd'
import axios from '../../libs/axios'
import baseUrl from './baseUrl'
const  FlashShare = props => {
    const { id } = props.match.params // 快讯的详情的ID
    const [ data,setData ] = useState({})
    const [ isLoading,setisLoading ] = useState(true)
    useEffect(()=>{
        // 请求快讯详情
        // console.log('props', props, )
        axios.post(`${baseUrl()}flash/detail`, {id}).then(res => {
            // console.log('res', res)
            if (res.code === 10000){
                setData(res.data)
                setisLoading(false)
            }
        })
    },[id])
  
    return (
        <section className='share-container'>
            <Skeleton active loading={isLoading}>
                <div className='share-cont text-container'>
                    <h2 className='title'>{data.name}</h2>
                    <h5 className='sub-title'>分享自【麻省理工科技评论】APP</h5>
                    {/* <article dangerouslySetInnerHTML={{__html:data.text}}></article> */}
                    {/* <p className='title2'>{data.name}</p> */}
                    {/* <p>丙二酰辅酶A是一种重要的微生物胞内代谢中间产物,由于其独特的结构,可衍生为几类具有独特结构的化合物,包括:脂肪酸类化合物、生物基化学品和植物源黄酮及聚酮类天然产物等.这些化合物广泛应用于食品、医药、化工和能源等领域。</p>
                    <p>目前,微生物大量合成上述丙二酰辅酶A衍生物的限制性因素是其胞内较低的丙二酰辅酶A含量.本文中,笔者以提高微生物合成丙二酰辅酶A衍生物为核心,综述了提高其前体丙二酰辅酶A导向目标产物的代谢工程策略,包括丙二酰辅酶A合成途径的强化、竞争支路途径的消减以及其含量的精细调控等,以期为微生物合成丙二酰辅酶A衍生物的进一步研究提供参考。</p> */}
                    <article dangerouslySetInnerHTML={{__html:data.content}}></article>
                </div>
                <FooterShare />
            </Skeleton>
            
        </section>
    )
}
export default FlashShare