
import React, { Component } from 'react'
import { Skeleton } from 'antd'
interface stateType{
    load: boolean
}
interface propsType{
    item: any,
    index: any
}
class HotItem extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            load: false
        }

    }
    
    componentDidMount(){
    }

    loadSuccess = () => {
        this.setState({
            load: true
        })
    }
    render() {
        const {item, index} = this.props
        const {load} = this.state
        return (
            <dl className="hotnews-item" key={item.id}>
                <a href={`/news/detail/${item.id}`}>
                    {/* <dt><Avatar photoUrl={item.cover} photoWidth={'68px'} photoHeight={'68px'} isRadius={false}></Avatar><span>{index + 1}</span></dt> */}
                    <dt><img src={item.cover + '?imageView2/2/w/68/h/68'} alt="" onLoad={this.loadSuccess}/><span>{index + 1}</span></dt>
                    {
                        !load ? <Skeleton active className="loadingImg"/> : null
                    }
                </a>
                <a href={`/news/detail/${item.id}`}>
                    <dd title={item.name}>{item.name}</dd>
                </a>
            </dl>
        )
    }
}
export default HotItem

