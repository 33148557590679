import React, { Component } from 'react'
import { Form, Button } from 'antd'
import 'styles/regist/regist.scss'
import className from 'classname'
interface stateType {
    params:{
        mobile: string,
        pwd: string,
        pwd2: string,
        code: string,
        email: string,
        nick_name: string
    },
    type: number,
    isCheck: boolean,
    codeText: string,
    codeBtnisAble: boolean,
    maxTime: number,
    pwdplaceholder: boolean
}
interface propsType {
}

class Forget extends Component<propsType, stateType> {
    constructor(props) {
        super(props)
        this.state = {
            params:{
                mobile: '',
                pwd: '',
                pwd2: '',
                code: '',
            },
            type:2,
            isCheck: false,
            codeText:'获取验证码',
            codeBtnisAble: false,
            maxTime: 60,
            pwdplaceholder: true
        }
    }

    componentDidMount(){

    }

    //手机号校验
    firstChecked = (rule, value) => {
        const regPhone = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
        if (!value) {
            return Promise.reject('手机号不能为空')
        } else {
            if (!regPhone.test(value)) {
                return Promise.reject('手机号格式不正确 请重新输入')
            }
        }
        return Promise.resolve()
    }

    //密码校验
    pwdChecked = (rule, value) => {
        // let reg = /^(?![A-z0-9]+$)(?![A-z~@*()_]+$)(?![0-9~@*()_]+$)([A-z0-9~@*()_]{6,20})$/
        let reg = /(?=.*[a-z])(?=.*\d)(?=.*[#@!~%^&*+=_])[a-z\d#@!~%^&*+=_]{6,20}/
        if (!value){
            return Promise.reject('密码不能为空')
        } else if (!reg.test(value) || value.length < 6 || value.length > 20){
            return Promise.reject('您输入的密码不符合规则,请重新输入')
        }
        return Promise.resolve()
    }
    
    //重置密码校验
    pwd2Checked = (rule, value) => {
        if (!value){
            return Promise.reject('密码不能为空')
        } else if (value !== this.state.params.pwd){
            return Promise.reject('两次输入的密码不一致，请重新输入')
        }
        return Promise.resolve()
    }

    handleFinish = values => {
        // console.log('allValues', values)
        let params = this.state.params
        this.axios.post('/find', params).then(res => {
            if (res.code === 10000){
                window.location.href = '/login'
            }
        })
    }

    handleChange = (changedValues, allValues) => {
        // console.log('changedValues', changedValues)
        let params = Object.assign({}, this.state.params, changedValues)
        this.setState({params}, () => {
            // console.log('this.state.params', this.state.params)
        })
    }

    //修改密码
    changeCode = () => {
        let params = this.state.params
        this.axios.post('/find', params).then(res => {
            if (res.code === 10000){
                window.location.href = '/login'
            }
        })
    }

    //获取验证码
    getCode = values => {
        const regPhone = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/
        let mobile = this.state.params.mobile
        if (mobile && regPhone.test(mobile)) {
            const params = Object.assign({},{mobile: mobile}, {type: this.state.type})
            this.axios.post('/code', params).then(res => {
                this.setState({ codeBtnisAble:true })
                let siv = setInterval(() => {
                    this.setState( preState => ({
                        maxTime: preState.maxTime - 1,
                        codeText: `${preState.maxTime - 1}s`
                    }), () => {
                        if (this.state.maxTime === 0) {
                            clearInterval(siv)
                            this.setState({
                                codeBtnisAble: false, codeText: "重新获取验证码", maxTime: 60
                            })
                        }
                    })
                }, 1000)
            })
        }
        
    }

    //输入密码
    pwdInput = () => {
        this.setState({
            pwdplaceholder: false
        })
    }

    //阅读用户协议
    changeBox = e => {
        this.setState({
            isCheck: e.target.checked
        })
    }
    render() {
        const {codeText, codeBtnisAble, pwdplaceholder, params} = this.state
        return (
            <div className="regist-box">
                <div className="title">密码重置</div>
                <div className="content">
                    <Form name='regist' onFinish={this.handleFinish} onValuesChange={this.handleChange}>
                        <Form.Item
                            className='item'
                            name='mobile'
                            rules={[{ validator: this.firstChecked }]}
                            validateTrigger='onChange'
                        >
                            <input type="text" placeholder="请输入手机号" />
                        </Form.Item>
                        <Form.Item
                            className='item'
                            name='code'
                            rules={[{required:true, message:'短信验证码不能为空'}]}
                            validateTrigger='onChange'
                        >
                            <div>
                                <input type="text" placeholder="请输入验证码" />
                                <Button className='vercode' onClick={this.getCode} disabled={codeBtnisAble}>{codeText}</Button>
                            </div>
                        </Form.Item>
                        <Form.Item
                            className='item'
                            name='pwd'
                            rules={[{validator: this.pwdChecked}]}
                            validateTrigger='onChange'
                        >
                            <div className="pwdcon">
                                <input type="password" placeholder="请输入密码" onInput={this.pwdInput} />
                                <span className={className([{'pwdplace' : !pwdplaceholder && params.pwd}])}>6-20位字母、数字、符号组合</span>
                            </div>
                        </Form.Item>
                        <Form.Item
                            className='item'
                            name='pwd2'
                            rules={[{ validator: this.pwd2Checked }]}
                            validateTrigger='onChange'
                        >
                            <input type="password" placeholder="请再次输入密码" />
                        </Form.Item>
                        <div className="foot">
                            <button type="submit" className="btnregist"><span>确定</span><img src={require('assets/images/arrows.svg')} alt="" className="arrows" /></button>
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

export default Forget