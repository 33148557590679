
import React, { Component } from 'react'
import 'styles/components/footer.scss'
interface stateType{
}
interface propsType{
}
class Footer extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            
        }

    }
    
    componentDidMount(){
    }
    render() {
        return (
            <div className="footer">
                <div className="footer-con">
                    <div className="logbox">
                        <img src={require('assets/images/logo-footer.svg')} alt="" />
                        <div className="list">
                            <a  href='/about'>\ 关于我们</a>
                            <a  href='/about'>\ 加入我们</a>
                            <a  href='/about'>\ 寻求报道</a>
                        </div>
                    </div>
                    <div className="company">
                        <p>© 2017<a href="https://beian.miit.gov.cn/">京ICP备16049925号-2</a>北京演绎科技有限公司</p>
                        <p><a href="https://dxzhgl.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%25E5%258C%2597%25E4%25BA%25AC%25E6%25BC%2594%25E7%25BB%258E%25E7%25A7%2591%25E6%258A%2580%25E6%259C%2589%25E9%2599%2590%25E5%2585%25AC%25E5%258F%25B8&type=xuke">京B2-20224042</a></p>
                        <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502040579"><p><img src={require('assets/images/police.png')} alt="" />京公网安备 11010502040579号</p></a> 
                    </div>
                </div>
            </div>
        )
    }
}
export default Footer

