/* eslint-disable no-mixed-spaces-and-tabs */
import React               from 'react'
import PropTypes from 'prop-types'
import Home from 'pages/home'
import { Layout } from 'antd'
import Nav from 'pages/nav/Nav'
import Footer from 'pages/footer/Footer'

class WebLayout extends React.Component {
    static propTypes = {
    	children: PropTypes.node
    }
	state = {
	}
	// 跳转之后重新回到顶端 router4.x的坑
	componentDidUpdate(prevProps) {
    
	}
	componentDidMount(){
		   function setRem() {
		  	 const baseSize = 16
		  	 //  当前页面宽度相对于 1240 宽的缩放比例，可根据自己需要修改。
		  	 const clientWidth = document.documentElement.clientWidth 
		  	 // 设置页面根节点字体大小
		  	 document.body.style.fontSize = clientWidth / 1240 + 'px'
		  	 document.documentElement.style.fontSize = clientWidth / 1240 + 'px'
		  	//  console.log("1rem = ",document.body.style.fontSize)
		   }
		 //    setRem() //调用
		 //     window.addEventListener('orientationchange', setRem)//设备横竖屏切换时
		 //    window.addEventListener('resize', setRem)//屏幕大小改时
		 document.title = '麻省理工科技评论-发现改变世界的新兴科技'
	}
	
	render() {
		 const { pathname } = this.props.location
    	return (
    		<Layout className='app-container'>
			  <Nav/>
				 {
	                pathname.includes('/video/detail') ? this.props.children
	                : <section className='container'>
	                        {this.props.children}
	                    </section> 
	            }		
			 <Footer/>
    		</Layout>
    	)
	}
}

export default WebLayout
