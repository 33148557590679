
import React, { Component } from 'react'
import { Link ,withRouter} from 'react-router-dom'
import { connect } from 'react-redux'
import { message } from 'antd'
import 'styles/nav/nav.scss'
import className from 'classname'
import Dialog from 'rc-dialog'
import { removeStorage, getStorage } from 'utils/storage'
import Avatar from 'comps/Avatar'
import { getusers } from '@redux/user/action'
interface stateType{
    navList: any[],
    currentIndex: any,
    lists: any[],
    activity: any[],
    delVisible:Boolean,
    inpVal: string,
    navRightNone: boolean,
    hotData: any[],
    loginStatus: boolean,
    loginedStatus: boolean,
    userData: any,
    childIndex: any
}
interface propsType{
    location: any,
    history: any,
    getusers: Function,
    defaultUsers: any
}
@connect(state => state, { getusers })
class Nav extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            navList: [
                { id: 1, title: '资讯', link: '/news' },
                { id: 2, title: '快讯', link: '/breaking' },
                { id: 3, title: '视频', link: '/video' },
                { id: 4, title: '评选', link: '/list', items:[
                    // {id:1, title: '35岁以下科技创新35人'},
                    // {id:2, title: '50家聪明公司'},
                    // {id:3, title: '全球十大突破性技术'},
                ] },
                { id: 5, title: '活动', link: '/activity',items:[
                    // {id:1, title:'EmTech China 第一届', url: 'http://emtechchina.cn/jan2018'},
                    // {id:2, title:'EmTech China 第二届', url: 'http://emtechchina.cn/jan2019'},
                    // {id:3, title:'EmTech China 第三届', url: 'http://emtechchina.cn/dec2019'},
                ] },
             
            ],
            currentIndex: 1,
            lists: [],
            activity: [],
            delVisible: false,
            inpVal:'',
            navRightNone: false,
            hotData: [],
            loginStatus: false,
            loginedStatus: false,
            userData: {},
            childIndex: null
        }

    }
    
    componentDidMount(){
        this.checkPath()
        this.getlists()
        this.getActivity()
        this.getHotData()
        this.props.getusers()
        // console.log(this.props.defaultUsers)
    }
    componentDidUpdate(preveProps,preveState) {
        if (preveProps.location.pathname !== this.props.location.pathname) {
            this.setState({
                loginedStatus: false
            })
            this.checkPath()
        }
       
    }

    //导航选中
    checkPath = () => {
        const findResult = this.state.navList.find(val => this.props.location.pathname.includes(val.link))

        if (this.props.location.pathname.includes('newsflash')){
            this.setState({
                currentIndex: 2
            })
        } else {
            if (findResult) {
                this.setState({
                    currentIndex: findResult.id
                })
            } else if (this.props.location.pathname === '/' || this.props.location.pathname === '/news'){
                this.setState({
                    currentIndex: 1
                })
            } else {
                this.setState({
                    currentIndex: null
                })
            }
        }
    }

    //导航切换
    handleClick = id => {
        this.setState({
            currentIndex: id,
            loginStatus: false,
            loginedStatus:false,
            childIndex: id
        })
    }

    //评选
    getlists = () => {
        this.axios.get('/nav/lists').then(res => {
            if (res.code === 10000){
                let navList = this.state.navList
                navList[3].items =  res.data
                this.setState({
                    navList
                })
            }
        })
    }

    //活动
    getActivity = () => {
        this.axios.get('/nav/activity').then(res => {
            if (res.code === 10000){
                let navList = this.state.navList
                navList[4].items =  res.data
                this.setState({
                    navList
                })
            }
        })
    }

    //点击活动或评选
    clickListActivity = item => {
        if (!item.length){
            // message.error('敬请期待')
            message.error({
                content: <div className="toast">
                    <span>敬请期待!</span>
                </div>,
                className: 'toast-box',
                style: {
                    marginTop: '5vh'
                }
            },1)
        }
    }

    //点击登录
    handleLogin = () =>{
        this.props.history.push('/login')
        this.setState({
            loginStatus: true
        })
    }

    //展开登录状态
    handleLogined = () => {
        this.setState({
            loginedStatus: true,
            // currentIndex: null,
        })
    }
    
    //退出登录
    exit = () =>{
        this.axios.post('/logout').then(res => {
            if (res.code === 10000){
                removeStorage('token')
                message.success('退出成功')
                this.props.history.push('/news')
                this.setState({
                    loginedStatus: false,
                    currentIndex: 1,
                })
            }
        })
        
        
    }

    //账号设置
    handleSet = () => {
        this.setState({
            loginedStatus: false
        }, () => {
            this.props.history.push('/user')
        })
        
    }
    //input聚焦
    inpFocus = () => {
        this.setState({
            // delVisible: true
        })
    }

    //input输入值
    inpChange = e => {
        this.setState({
            delVisible: true,
            inpVal: e.target.value
        })
    }

    //显示搜索框
    handleSearch = () => {
        this.setState({
            navRightNone: true
        })
    }

    //关闭搜索
    closeSearch = () => {
        this.setState({
            navRightNone: false,
            inpVal: '',
            delVisible: false
        })
    }

    //点击热门搜索
    clickHot = val => {
        if (val){
            this.setState({
                inpVal: '',
                navRightNone: false
            }, () => {
                this.createHotData(val)
                this.props.history.push({pathname:'/search', state:{val}})
            })
        }
        
    }

    //input回车
    inpKeyDown = e => {
        var evt = window.event || e
        if (evt.keyCode === 13){
            let inpVal = this.state.inpVal
            if (inpVal.trim()){
                this.setState({
                    navRightNone: false,
                    inpVal: ''
                }, () => {
                    this.createHotData(inpVal)
                    this.props.history.push({pathname:'/search', state:{val: inpVal}})
                })
                
            }
            
        }
    }

    //清空input值
    deleteInputVal = () => {
        this.setState({
            inpVal: ''
        })
    }

    //获取热门搜索词
    getHotData = () => {
        let params = {limit: 20}
        this.axios.get('/hotWord/index', {params}).then(res => {
            if (res.code === 10000){
                this.setState({
                    hotData: res.data
                })
            }
        })
    }

    //创建热门搜索词
    createHotData = val => {
        let word = val
        this.axios.post('/hotWord/create',{word}).then(res => {
            if (res.code === 10000){
                // console.log(res)
            }
        })
    }
    
    //登录按钮鼠标滑过
    loginMouseMovew = () => {
        this.setState({
            // currentIndex: null,
            loginedStatus: false
        })
    }

    mouseEnterNav = id => {
        this.setState({
            currentIndex: id,
            childIndex: id
        })
    }

    Mouseleave = id => {
        this.setState({
            childIndex: null,
        })
    }
    render() {
        const {navRightNone, hotData, loginStatus, loginedStatus, currentIndex, childIndex} = this.state
        const userData = this.props.defaultUsers
        return (
            <div className="nav-top">
                <div className="nav-con">
                    <div className="nav-logo">
                        <a href="/news"><img src={require('assets/images/logo.svg')} alt=""/></a>
                    </div>
                    <div className="nav-right">
                        {/* {
                            this.state.navList.map(item => {
                                return <div className={className("nav-item",[{'nav-item-active': currentIndex === item.id}, {'nav-item-none': childIndex !== item.id}])} onClick={() => this.handleClick(item.id)}  onMouseEnter={() =>this.mouseEnterNav(item.id)} onMouseLeave={() => this.Mouseleave(item.id)}>
                                    {
                                        item.title === '评选' || item.title === '活动' ? <span className="links" onClick={() => this.clickListActivity(item.items)}>{item.title}
                                            {
                                                item.items && item.items.length ? <div className={className("nav-child")}>
                                                    {
                                                        item.items.map(ite => {
                                                            return <div onClick={() => window.location.href = ite.url}>{ite.name || ite.title}</div>
                                                        }) 
                                                    }
                                                </div> : null
                                            }
                                        </span> : <Link to={item.link} key={item.id} className="linkantd">{item.title}</Link>
                                    }
                                    
                                    
                                </div>
                            })
                        } */}

                        {
                            this.state.navList.map(item => {
                                return <div className="nav-item" key={item.id}>
                                    {
                                        item.title === '评选' || item.title === '活动' ? <span className="links" onClick={() => this.clickListActivity(item.items)}>{item.title}
                                            {
                                                item.items && item.items.length ? <div className={className("nav-child")}>
                                                    {
                                                        item.items.map(ite => {
                                                            return <div onClick={() => window.location.href = ite.url}  key={ite.id}>{ite.name || ite.title}</div>
                                                        }) 
                                                    }
                                                </div> : null
                                            }
                                        </span> : <Link to={item.link} key={item.id} className={className(["linkantd",{'linkantd-active': currentIndex === item.id}])}  onClick={() => this.handleClick(item.id)} >{item.title}</Link>
                                    }
                                </div>
                            })
                        }
                        <img src={require('assets/images/search.svg')} alt="" className="search" onClick={this.handleSearch}/>
                        {
                            !getStorage('token') ? <div className={className("login",[{'login-actve': loginStatus}])} onClick={this.handleLogin} >
                                <span>登录</span>
                                <span className="arrows"></span>
                            </div> : <div className="logined" onMouseEnter={this.handleLogined} onMouseLeave={this.loginMouseMovew}>
                                {
                                    userData.avatar ? <Avatar photoUrl={userData.avatar} photoWidth={'36px'} photoHeight={'36px'} isRadius={true}></Avatar> : <img src={require('assets/images/user/user.png')} alt=""/>
                                }
                                <div className="name">{userData.nick_name}</div>
                                <div className={className("setting",[{'setting-active': loginedStatus}])}>
                                    <div onClick={this.handleSet}>账号设置</div>
                                    <div onClick={this.exit}>退出登录</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <Dialog
                    prefixCls="search"
                    animation="fade"
                    maskClosable={false}
                    mask={false}
                    destroyOnClose= {true}
                    visible={this.state.navRightNone}
                    closeIcon= {<img src={require('assets/images/search/search-del.png')} style={{width:36,height:36}} alt="close"/>}
                    onClose={this.closeSearch}
                >
                    <img src={require('assets/images/logo.svg')} alt='logo' className='search-logo' />
                    <div className={className("search-content",{'search-content-show': navRightNone})}>
                        {/* <img src={require('assets/images/search/search-del.png')} alt="" className="search-del" onClick={this.closeSearch}/> */}
                        <div className="searchbox">
                            <input type="text" placeholder="请输入关键词" className={className("search",{"searchpad": this.state.delVisible})} onFocus={this.inpFocus} value={this.state.inpVal} onChange={this.inpChange} onKeyDown={this.inpKeyDown}/>
                            <img src={require('assets/images/search/search-nav.png')} alt="" className={className("searchbtn",{"searchbtnnone": this.state.delVisible})}/>
                            <img src={require('assets/images/delete.png')} alt="" className={className("delete",{'delactive': this.state.delVisible})} onClick={this.deleteInputVal}/>
                            {/* <ul className={className('associative', {'associativeshow': this.state.inpVal})}>
                                <li>人工智能</li>
                                <li>人工智能</li>
                                <li>人工智能</li>
                            </ul> */}
                            {/* <div className="remind">
                                <img src={require('assets/images/search/tixing.png')} alt=""/>
                                以下为您显示“<span>人工智能</span>”的搜索结果，仍然搜索:<span>人工智能</span>
                            </div> */}
                            <div className="hot-search">
                                <div className="title">热门搜索</div>
                                <div className="typeList">
                                    {
                                        hotData.map(item => {
                                            return <span onClick={() => this.clickHot(item)} key={item}>{item}</span>
                                        })
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </Dialog>
                
            </div>
        )
    }
}

export default withRouter(Nav)

