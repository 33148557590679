import moment from 'moment'
export default function fromatTime(dateTimeStamp,unnormal){
    var seconds = 1000
    var minute = 1000 * 60
    var hour = minute * 60
    var day = hour * 24
    var now = new Date().getTime()
    var diffValue = now - dateTimeStamp
    if (diffValue < 0) {return}
    var weekC = diffValue / (7 * day) 
    var dayC = diffValue / day
    var hourC = diffValue / hour
    var minC = diffValue / minute
    var secondsC = diffValue / seconds
    let result

    if (weekC >= 1){
        result =  unnormal ? moment(dateTimeStamp).format('YYYY-MM-DD') : moment(dateTimeStamp).format('YYYY年MM月DD日')
    } else if (dayC >= 1 && weekC < 1){
        result = "" + parseInt(dayC) + "天前"
    } else if (hourC >= 1 && dayC < 1){
        result = "" + parseInt(hourC) + "小时前"
    } else if (minC >= 1 && hourC < 1){
        result = "" + parseInt(minC) + "分钟前"
    } else  if (secondsC >= 1 && minC < 1){
        result = "" + parseInt(secondsC) + "秒前"
    }      
    return result
}