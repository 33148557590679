import Layout from 'pages/layout'
import Information from 'pages/information/Information'
import Newsflash from 'pages/newsflash/Newsflash'
import Video from 'pages/video/Video'
import VideoDetail from 'pages/video/VideoDetail'
import List from 'pages/list/List'
import Activity from 'pages/activity/Activity'
import Login from 'pages/login/Login'
import Search from 'pages/search/Result'
import InformationDetail from 'pages/information/InformationDetail'
import Author from 'pages/author/Author'
import AuthorDetail from 'pages/author/AuthorDetail'
import Contract from 'pages/Contract'
import Regist from 'pages/regist/Regist'
import Forget from 'pages/login/Forget'
import User from 'pages/user/User'
import EmtechColumn from 'pages/emtechColumn/index'
const PrivateRoutes = [{
    path: '/',
    component: Layout,
    childRoutes: [
        { path: '/', component: Information  },
        { path: '/news', component: Information },
        { path: '/breaking', component: Newsflash },
        { path: '/video', component: Video },
        { path: '/video/detail/:id', component: VideoDetail },
        { path: '/list', component: List },
        { path: '/activity', component: Activity },
        { path: '/login', component: Login },
        { path: '/search', component: Search },
        { path: '/news/detail/:id', component: InformationDetail },
        { path: '/about', component: Author },
        { path: '/author/detail/:id', component: AuthorDetail },
        { path: '/contract', component: Contract },
        { path: '/regist', component: Regist },
        { path: '/forget', component: Forget },
        { path: '/user', component: User },
        { path: '/emtechcolumn', component: EmtechColumn }
    ]
}]

export default PrivateRoutes
