
import React, { Component } from 'react'
import { Skeleton } from 'antd'
import 'styles/video/video.scss'
import Subscribe from 'comps/subscribe/Subscribe'
import VideoItem from 'comps/video/VideoItem'
import SwiperCom from './Swiper'
import { connect } from 'react-redux'
interface stateType{
    videoList: any[],
    params:object,
    videoLoad: boolean,
    total: number,
    hotNews: any[]
}
interface propsType{
    getBannerList: any[]
}
class Video extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            videoList:[],
            params:{
                page:1,
                limit:12
            },
            videoLoad:true,
            total:0,
            hotNews: []
        }

    }
    
    componentDidMount(){
        this.getVideoList()
        this.getHotNews()
    }

    //视频列表
    getVideoList = () => {
        let params = this.state.params
        this.axios.get('/movie/index', {params}).then(res => {
            if (res.code === 10000){
                let videoList = this.state.videoList
                videoList.push(...res.data.items)
                this.setState({
                    videoList,
                    videoLoad:false,
                    total: res.data.total
                }, () => {
                })
            }
        })
    }

    //加载更多
    loadMore = () => {
        let page = this.state.params.page
        page ++
        let params = Object.assign({}, this.state.params, { page })
        this.setState({
            params
        }, () => {
            this.getVideoList()
        })
    }

    //最热
    getHotNews = () => {
        this.axios.get('/information/hot',{params:{limit:3}}).then(res => {
            if (res.code === 10000){
                this.setState({
                    hotNews: res.data
                })
            }
        })
    }
    render() {
        const {getBannerList} = this.props
        const {videoList, videoLoad, total, hotNews} = this.state
        return (
            <div className="video-con">
                {
                    getBannerList.top ? getBannerList.top && getBannerList.top.url ? <a href={getBannerList.top.url}><img src={getBannerList.top && getBannerList.top.img} alt="" className="advertisement"/></a> : <img src={getBannerList.top && getBannerList.top.img} alt="" className="advertisement"/> : null
                }
                {/* <a href={getBannerList[0].url}><img src={getBannerList[0].img} alt="" className="advertisement"/></a> */}
                <div className="video-describe">
                    <div className="video-box">
                        <SwiperCom></SwiperCom>
                    </div>
                    <div className="describe-box">
                        <Subscribe></Subscribe>
                        <div className="weekhot">
                            <span className="little-title">本周最热</span>
                            <div className="hotnews">
                                <a href={`/news/detail/${hotNews[0] && hotNews[0].id}`}>
                                    <div className="news-item">
                                        <img src={require('assets/images/video/rank1.png')} alt=""/>
                                        <span title={hotNews[0] && hotNews[0].name}>{hotNews[0] && hotNews[0].name}</span>
                                    </div>
                                </a>
                                <a href={`/news/detail/${hotNews[1] && hotNews[1].id}`}>
                                    <div className="news-item">
                                        <img src={require('assets/images/video/rank2.png')} alt=""/>
                                        <span title={hotNews[1] && hotNews[1].name}>{hotNews[1] && hotNews[1].name}</span>
                                    </div>
                                </a>
                                <a href={`/news/detail/${hotNews[2] && hotNews[2].id}`}>
                                    <div className="news-item">
                                        <img src={require('assets/images/video/rank3.png')} alt=""/>
                                        <span title={hotNews[2] && hotNews[2].name}>{hotNews[2] && hotNews[2].name}</span>
                                    </div>
                                </a>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {
                    getBannerList.middle ? getBannerList.middle && getBannerList.middle.url ? <a href={getBannerList.middle.url}><img src={getBannerList.middle && getBannerList.middle.img} alt="" className="advertisement2"/></a> : <img src={getBannerList.middle && getBannerList.middle.img} alt="" className="advertisement2"/> : null
                }
                {/* <a href={getBannerList[1].url}><img src={getBannerList[1].img} alt="" className="advertisement2"/></a> */}
                <Skeleton active loading={videoLoad}>
                    <div className="videolist">
                        {
                            videoList && videoList.map(item => {
                                return <VideoItem videoData={item}></VideoItem>
                            })
                        }
                    </div>
                </Skeleton>
                {
                    total > videoList.length ? <div className="loadMore" onClick={this.loadMore}>加载更多</div> : null
                }

            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        getBannerList: state.getBannerList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(Video)

