
import React, { Component } from 'react'
interface stateType{
}
interface propsType{
}
class List extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            
        }

    }
    
    componentDidMount(){
    }
    render() {
        return (
            <div className="list-con">
               list
            </div>
        )
    }
}
export default List

