import React from 'react'
import 'styles/h5share/agree.scss'

const ContactUs = () => {
    return <section className='h5agree contactus'>
        <div className="title">麻省理工科技评论</div>
        <div className='sendemail'>任何问题请发送邮件至：<br/>mittrapp@mittrchina.com</div>
        <img src={require('assets/images/appimg/wechatcode.png')} alt="" className='wechatcode'/>
        <div className="addwechat">或添加DT君微信：DeepTech03</div>
        <div className="res">为您解答APP相关问题</div>
    </section>
}
export default ContactUs