
import React, { Component } from 'react'
import 'styles/components/videoitem.scss'
import fromatTime from 'utils/formatTime'
import { Skeleton } from 'antd'
interface stateType{
    load: boolean
}
interface propsType{
    videoData: object
}
class VideoItem extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            load: false
        }

    }
    
    componentDidMount(){
    }

    loadSuccess = () => {
        this.setState({
            load: true
        })
    }
    render() {
        const {videoData} = this.props
        const {load} = this.state
        return (
            <a href={`/video/detail/${videoData.id}`} key={videoData.id}>
                <div className="videoitem">
                    <div className="little-title">{fromatTime(videoData.start_time * 1000, 'video')}</div>
                    <div className="title-con">{videoData.title} </div>
                    {/* <img src={require('assets/images/information/example.jpg')} alt=""/> */}
                    <div className="video">
                        <img src={videoData.img + '?imageView2/2/w/295/h/178'} alt=""  onLoad={this.loadSuccess}/>
                        <div className="playBtn"></div>
                        {
                            !load ? <Skeleton active className="loadingImg"/> : null
                        }
                    </div>
                </div>
            </a>
        )
    }
}
export default VideoItem

