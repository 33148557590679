
const defaultState = {
    avatar: '',
    mobile: '',
    email: '',
    nick_name: '',
}
function defaultUsers(state = defaultState, action) {
    const { type, payload } = action
    let stateCopy = state
    switch (type){
    case 'USERS' :
        stateCopy = Object.assign({}, state, payload)
        // console.log('stateCopy',stateCopy)
        return stateCopy
    default:
        return state
    }
}

export {defaultUsers}
