
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Skeleton } from 'antd'
import 'styles/emtechColumn/index.scss'
import className from 'classname'
import SearchItem from 'comps/search/SearchItem'
import VideoItem from 'comps/video/VideoItem'
interface stateType{
    tabs:any[],
    current:Number,
    dataList: any[],
    total: number,
    load: boolean,
    page: number
}
interface propsType{
    location: any,
    getBannerList: any[]
}
class emtechColumn extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            tabs:[
                {id:0, name:'资讯'},
                {id:1, name:'视频'}
            ],
            current:0,
            dataList: [],
            total: 0,
            load: true,
            page: 1
        }

    }

    componentDidMount(){
        // this.scrollToBottom()
        this.getInformation()
    }

    //tab切换
    clickTabs = ind => {
        // console.log(ind)
        document.body.scrollIntoView()
        this.setState({
            current: ind,
            load: true,
            page: 1,
            dataList: []
        }, () => {
            if (ind === 0){
                this.getInformation()
            } else {
                this.getVideo()
            }
        })


    }

    //资讯
    getInformation = () => {

        this.axios.get('/information/list',{params: {page: this.state.page, limit: 10}}).then(res => {
            if (res.code === 10000){
                let dataList = this.state.dataList
                dataList.push(...res.data.items)
                this.setState({
                    dataList,
                    total: res.data.total,
                    load: false
                })
            }
        })
        
    }

    //视频
    getVideo = () => {
        this.axios.get('/movie/list',{params: {page: this.state.page, limit: 9}}).then(res => {
            if (res.code === 10000){
                // console.log('videores', res)
                let dataList = this.state.dataList
                dataList.push(...res.data.items)
                this.setState({
                    dataList,
                    total: res.data.total,
                    load: false
                })
            }
        })
    }


    //滚动轴到底部
    scrollToBottom = () => {
        window.onscroll = () => {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
            // console.log('scrollTop', scrollTop)
            // console.log('clientHeight', clientHeight)
            // console.log('scrollHeight', scrollHeight) 
            let {page, total, dataList, current} = this.state
            if  (scrollTop > 0 && Math.ceil(clientHeight) + Math.ceil(scrollTop) >= Math.ceil(scrollHeight)) {
                page ++
                // console.log('total', this.state.total)
                // console.log('dataList', this.state.dataList)
                this.setState({page}, () => {
                    if (total > dataList.length){
                        // window.scrollTo(0, scrollTop - 5)
                        if (current === 0){
                            this.getInformation()
                        } else {
                            this.getVideo()
                        }
                    }
                })
            }
        }
    }

    //加载更多
    loadMore = () => {
        let page = this.state.page
        page ++
        this.setState({
            page
        }, () => {
            if (this.state.current === 0){
                this.getInformation()
            } else {
                this.getVideo()
            }
        })
    }
    componentWillUnmount(){
        // 卸载滚动事件
        // document.removeEventListener('scroll',this.scrollToBottom,false)
        window.onscroll = null
    }
    render() {
        const {dataList, load, current,total} = this.state
        const {getBannerList} = this.props
        return (
            <div className="emtechcolumn">
                {
                    getBannerList.top ? <a href={getBannerList.top.url}><img src={getBannerList.top && getBannerList.top.img} alt="" className="advertisement"/></a> : null
                }
                {/* <a href='/'><img src='https://image.deeptechchina.com/article/2021062516215299276.jpg' alt="" className="advertisement"/></a> */}
                <div className="searchbox">
                    <Skeleton active loading={load}>
                        <div className="resultList">
                            <div className="check-box">
                                <div className="tabs">
                                    {
                                        this.state.tabs.map((item, index) => {
                                            return <span className={className({'active': this.state.current === index})} onClick={() => this.clickTabs(index)}>{item.name}</span>
                                        })
                                    }
                                </div>
                            </div>
                            {current === 0 ? <div className="informationList">
                                {
                                    total > 0  && dataList.length ? dataList.map(item => {
                                        return <SearchItem dataList={item} type={'news'} emtechColumn={true}></SearchItem>
                                    }) : <img src={require('assets/images/null-img.png')} alt="" className="null-img"/>
                                }
                            </div> : <div className="movieList">
                                {
                                    total > 0  && dataList.length ? dataList.map(item => {
                                        return <VideoItem videoData={item}></VideoItem>
                                    }) : <img src={require('assets/images/null-img.png')} alt="" className="null-img"/>
                                }
                            </div>}
                            {
                                total > dataList.length ? <div className={className(["loadMore", {'loadMoreactive': this.state.current === 1}])} onClick={this.loadMore}>加载更多</div> : null
                            }
                        </div>
                    </Skeleton>
                    
                </div>

            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        getBannerList: state.getBannerList
    }
}

function mapDispatchToProps(dispatch) {
    return {
        
    }
}
export default connect(mapStateToProps,mapDispatchToProps)(emtechColumn)

