
import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import { Skeleton } from 'antd'
import 'styles/search/search.scss'
import className from 'classname'
import SearchItem from 'comps/search/SearchItem'
import fromatTime from 'utils/formatTime'
interface stateType{
    tabs:any[],
    current:Number,
    delVisible:Boolean,
    inpVal: string,
    selectStatus:boolean,
    selectVal: string,
    params:{
        keyword: string,
        limit: number,
        page: number,
        order: number
    },
    dataList: any[],
    total: number,
    load: boolean,
    page: number
}
interface propsType{
    location: any
}
class Result extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            tabs:[
                {id:0, name:'文章'},
                {id:1, name:'快讯'},
                {id:2, name:'视频'}
            ],
            current:0,
            delVisible: false,
            inpVal: '',
            // inpVal: '',
            selectStatus:false,
            selectVal:'相关度',
            params:{
                keyword: '',
                limit: 20,
                page: 1,
                order: 1
            },
            dataList: [],
            total: 0,
            load: true,
            page: 2
        }

    }

    componentDidMount(){
        this.scrollToBottom()
        let params = Object.assign({}, this.state.params, {keyword: this.props.location.state ? this.props.location.state.val : ''})
        this.setState({
            inpVal:this.props.location.state ? this.props.location.state.val : '',
            params
        }, () => {
            this.getInformation()
        })
        // window.onload = () =>{
        //     this.setState({
        //         inpVal: ''
        //     })
        // }
    }


    componentDidUpdate(preveProps,preveState) {
        if (preveProps.location.state !== this.props.location.state) {
            let params = Object.assign({}, this.state.params, {keyword: this.props.location.state.val}, {limit: 20}, {page: 1})
            this.setState({
                inpVal: this.props.location.state.val,
                params,
                current: 0,
                dataList: [],
                page: 2
            }, () => {
                this.getInformation()
            })
        }
        // if (preveState.selectVal !== this.state.selectVal){
        //     let order =  this.state.selectVal === '最新发布' ? 2 : 1
        //     let params = Object.assign({}, this.state.params, {limit: 20}, {page: 1},{order})
        //     // console.log(params)
        //     this.setState({
        //         // inpVal: this.props.location.state.val,
        //         params,
        //         dataList: [],
        //         page: 20
        //     }, () => {
        //         let ind = this.state.current
        //         if (ind === 0){
        //             this.getInformation()
        //         } else if (ind === 1){
        //             this.getFlash()
        //         } else {
        //             this.getVideo()
        //         }
        //     })
        // }

    }

    inpFocus = () => {
        this.setState({
            delVisible: true
        })
    }

    inpChange = e => {
        // console.log('e.target.value', e.target.value)
        let params = Object.assign({}, this.state.params, {keyword: e.target.value})
        this.setState({
            inpVal: e.target.value,
            params
        })
    }

    //tab切换
    clickTabs = ind => {
        // console.log(ind)
        document.body.scrollIntoView()
        let params = Object.assign({}, this.state.params, {page: 1}, {limit: 20})
        this.setState({
            current: ind,
            load: true,
            params,
            page: 2,
            dataList: []
        }, () => {
            if (ind === 0){
                this.getInformation()
            } else if (ind === 1){
                this.getFlash()
            } else {
                this.getVideo()
            }
        })


    }

    //下拉框状态
    handleSelet = e => {
        // console.log(e.target.innerText)
        let order =  e.target.innerText === '最新发布' ? 2 : 1
        let params = Object.assign({}, this.state.params, {limit: 20}, {page: 1},{order})
        this.setState({
            selectStatus: !this.state.selectStatus,
            selectVal: e.target.innerText,
            load: true,
            params,
            dataList: [],
            page: 2
        }, () => {
            let ind = this.state.current
            if (ind === 0){
                this.getInformation()
            } else if (ind === 1){
                this.getFlash()
            } else {
                this.getVideo()
            }
        })
    }


    //文章
    getInformation = () => {

        let params = this.state.params
        // console.log('params', params)
        this.state.inpVal && this.axios.get('/information/search',{params}).then(res => {
            if (res.code === 10000){
                let dataList = this.state.dataList
                dataList.push(...res.data.items)
                // console.log('Informationres', dataList)
                this.setState({
                    dataList,
                    total: res.data.total,
                    load: false
                })
            }
        })
        
    }

    //快讯
    getFlash = () => {
        let params = this.state.params
        this.state.inpVal && this.axios.post('/flash/search',params).then(res => {
            if (res.code === 10000){
                // console.log('flashres', res)
                let dataList = this.state.dataList
                dataList.push(...res.data.items)
                this.setState({
                    dataList,
                    total: res.data.total,
                    load: false
                })
            }
        })
    }

    //视频
    getVideo = () => {
        let params = this.state.params
        this.state.inpVal && this.axios.get('/movie/search',{params}).then(res => {
            if (res.code === 10000){
                // console.log('videores', res)
                let dataList = this.state.dataList
                dataList.push(...res.data.items)
                this.setState({
                    dataList,
                    total: res.data.total,
                    load: false
                })
            }
        })
    }

    //关闭搜索
    closeSearch = () => {
        window.location.href = '/news'
    }

    //清空input值
    deleteInputVal = () => {
        this.setState({
            inpVal: ''
        })
    }

    //input回车
    inpKeyDown = e => {
        var evt = window.event || e
        if (evt.keyCode === 13){
            let params = Object.assign({},this.state.params, {keyword: this.state.inpVal}, {limit: 20}, {page: 1})
            this.setState({
                params,
                current: 0,
                dataList: [],
                page: 2,
                load: true
            }, () => {
                if (this.state.inpVal.trim()){
                    this.getInformation()
                    this.createHotData()
                    this.props.history.replace({pathname:'/search', state:{val: this.state.inpVal}})
                }
                
            })
            
            
        }
    }

    //创建热门搜索词
    createHotData = () => {
        let word = this.state.inpVal
        this.axios.post('/hotWord/create',{word}).then(res => {
            if (res.code === 10000){
                // console.log(res)
            }
        })
    }
    //滚动轴到底部
    scrollToBottom = () => {
        window.onscroll = () => {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
            // console.log('scrollTop', scrollTop)
            // console.log('clientHeight', clientHeight)
            // console.log('scrollHeight', scrollHeight) 
            let {page, total, dataList, current} = this.state
            if  (scrollTop > 0 && Math.ceil(clientHeight) + Math.ceil(scrollTop) >= Math.ceil(scrollHeight)) {
                page ++
                let params = Object.assign({},this.state.params,{page}, {limit: 10})
                // console.log('total', this.state.total)
                // console.log('dataList', this.state.dataList)
                this.setState({params, page}, () => {
                    if (total > dataList.length){
                        // window.scrollTo(0, scrollTop - 5)
                        if (current === 0){
                            this.getInformation()
                        } else if (current === 1){
                            this.getFlash()
                        } else {
                            this.getVideo()
                        }
                    }
                })
            }
        }
    }

    componentWillUnmount(){
        // 卸载滚动事件
        // document.removeEventListener('scroll',this.scrollToBottom,false)
        window.onscroll = null
    }
    render() {
        const {dataList, load, current,total} = this.state
        return (
            <div className="search-con">
                {/* <img src={require('assets/images/search/search-del.png')} alt="" className="search-del" onClick={this.closeSearch}/> */}
                <div className="searchbox">
                    <input type="text" placeholder="请输入关键词" className="search" onFocus={this.inpFocus} value={this.state.inpVal} onChange={this.inpChange}  onKeyDown={this.inpKeyDown}/>
                    <img src={require('assets/images/search/search.svg')} alt="" className="searchbtn"/>
                    <img src={require('assets/images/delete.png')} alt="" className="delete"  onClick={this.deleteInputVal}/>
                    {/* <ul className={className('associative', {'associativeshow': this.state.inpVal})}>
                        <li>人工智能</li>
                        <li>人工智能</li>
                        <li>人工智能</li>
                    </ul> */}
                    {/* <div className="remind">
                        <img src={require('assets/images/search/tixing.png')} alt=""/>
                        以下为您显示“<span>人工智能</span>”的搜索结果，仍然搜索:<span>人工智能</span>
                    </div> */}
                    <Skeleton active loading={load}>
                        <div className="resultList">
                            <div className="check-box">
                                <div className="tabs">
                                    {
                                        this.state.tabs.map((item, index) => {
                                            return <span className={className({'active': this.state.current === index})} onClick={() => this.clickTabs(index)}>{item.name}</span>
                                        })
                                    }
                                </div>
                            </div>
                            {/* ,{"selectopen": this.state.selectStatus} */}
                            {/* {
                                console.log('this.state.selectStatus', this.state.selectStatus)
                            } */}
                            <div className="select" onClick={this.handleSelet}>
                                {/* {this.state.selectStatus ? <img src={require('assets/images/expansion.png')} alt=""/> : <img src={require('assets/images/packup.png')} alt=""/>} */}
                                <div className="correlation"><span>{this.state.selectVal}</span><span className="arrow"></span></div>
                                <div className="lastest">{this.state.selectVal === '相关度' ? '最新发布' : '相关度'}</div>
                            </div>
                            
                            <div className="searchList">
                                {/* {
                                    total > 0 ? dataList.length &&  dataList.map(item => {
                                        let type
                                        if (current === 0){
                                            type = 'information'
                                        } else if (current === 1){
                                            return  <div className="items">
                                                <h1 title={item.name}>{item.name}</h1>
                                                <div className="intro" dangerouslySetInnerHTML={{ __html: item.content }} ></div>
                                                <div className="time">{fromatTime(item.push_time * 1000)}</div>
                                            </div>
                                        } else {
                                            type = 'video'
                                        }
                                        return <SearchItem dataList={item} type={type}></SearchItem>
                                    }) : <img src={require('assets/images/null-img.png')} alt="" className="null-img"/>
                                } */}
                               
                                {
                                    total > 0  && dataList.length ?   dataList.map(item => {
                                        let type
                                        if (current === 0){
                                            type = 'news'
                                        } else if (current === 1){
                                            return  <div className="items">
                                                <h1 title={item.name}>{item.name}</h1>
                                                <div className="intro" dangerouslySetInnerHTML={{ __html: item.content }} ></div>
                                                <div className="time">{fromatTime(item.push_time * 1000)}</div>
                                            </div>
                                        } else {
                                            type = 'video'
                                        }
                                        return <SearchItem dataList={item} type={type}></SearchItem>
                                    }) : <img src={require('assets/images/null-img.png')} alt="" className="null-img"/>
                                }
                            </div>

                        </div>
                    </Skeleton>
                    
                </div>

            </div>
        )
    }
}
export default withRouter(Result)

