import React from 'react'
import 'styles/h5share/agree.scss'

const Question = () => {
    return <section className='h5agree question'>
        {/* <div className="title">常见问题</div> */}
        <div className='req'>1、下载后出现闪退怎么办？</div>
        <div className='res'> 请在腾讯应用宝、Apple Store等官方应用市场下载最新版本，完全卸载后进行安装后重新登录。</div>
        <div className="req">2、为何微信和手机号注册的账号不能合并？</div>
        <div className="res">为了给用户提供更好的服务，我们对账号注册进行了限制，请您统一用微信号或手机号注册之后再绑定其他信息，微信和手机号属于两种登录方式，无法合并，请您理解。</div>
        <div className="req">3、苹果手机如何查看、更改或取消产品的订阅？</div>
        <div className="res">您可以点击“Apple ID”，向下滚动并点击“订阅‘选项卡，查看您的订阅状态。<br/>
        自动订阅取消可以参考苹果商店的取消订阅操作：<a href="https://support.apple.com/zh-cn/ht202039">https://support.apple.com/zh-cn/ht202039</a><br/></div>
        <div className="req">4、如果您在使用本产品的过程中遇到其他问题，请随时联系麻省理工科技评论APP专属客服（微信号：DeepTech03）</div>
    </section>
}
export default Question