
let defaultEmail = ''

function changeEmail(state = defaultEmail, action) {
    switch (action.type){
    case 'ADD_EMAIL':
        return state + action.text
    default:
        return defaultEmail
    }
}

export { changeEmail }