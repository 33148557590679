import { combineReducers } from 'redux'
// email
import { changeEmail } from './email/reducer'
//banner
import { getBannerList } from './banner/reducer'
//个人信息
import {defaultUsers} from './user/reducer'
export default combineReducers({ 
    changeEmail, getBannerList, defaultUsers
})


