import React, { Component } from 'react'
import 'styles/author/author.scss'
import { Skeleton } from 'antd'
interface stateType {
    autherList: any[],
    load: boolean
}
interface propsType {
}

class Author extends Component<propsType, stateType> {
    constructor(props) {
        super(props)
        this.state = {
            autherList: [],
            load: true
        }
    }

    componentDidMount(){
        this.getAutherList()
    }

    //作者列表
    getAutherList = () => {
        this.axios.get('/author/index').then(res => {
            if (res.code === 10000){
                this.setState({
                    autherList: res.data,
                    load: false
                })
            }
        })
    }
    render() {
        const {autherList, load} = this.state
        return (
            <div className="author-con">
                <Skeleton active loading={load}>
                    <div className="author-mission">
                        <div className='title'>我们的使命</div>
                        <div className="mission-body">
                        发现改变世界的新兴科技，构建一个全球新兴科技创新服务平台，影响那些有影响力的人。
                        </div>
                    </div>
                    <div className="author-team">
                        <div className='title'>我们的团队</div>
                        <div className="team-body">
                            {
                                autherList.map(item => {
                                    return <div className="team-item">
                                        <span>{item.job}</span>
                                        <span><a href={`/author/detail/${item.id}`}>{item.username}</a></span>
                                    </div>
                                })
                            }
                            
                        </div>
                    </div>
                    <div className="author-join-us">
                        <div className='title'>加入我们</div>
                        <div className="join-us-body">
                            <div className="join-us-top">
                                <span>1、轻松友好的工作环境；</span>
                                <span>2、充满想象力的工作内容；</span>
                                <span>3、野心勃勃且清晰明确的发展规划；</span>
                                <span>4、不低于行业平均水平的工资，以及足够的提升空间；</span>
                                <span>5、诚意十足的股权激励；</span>
                                <span>6、定期的海外科技之旅；</span>
                                <span>7、零距离接触国内外科技大牛的机会。</span>
                            </div>
                            <div className="join-us-bottom">
                                <div>招聘职位：</div>
                                <div>
                                    <span>生物编辑</span>
                                    <span>记者</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="report">
                        <div className="title">寻求报道</div>
                        <div className="report-con">
                            <div>《麻省理工科技评论》（MIT Technology Review）于1899年在美国麻省理工学院创刊，是世界上历史最悠久、影响力最大的技术商业类杂志。内容覆盖广泛，涉及互联网、通讯、计算机技术、能源、新材料、生物医学和商务科技几大领域。我们始终坚信，科技代表的是人类未来，而一个真正科技内容品牌存在的意义，就是为了将这个未来更好地呈现给用户。</div> 
                            <div>《麻省理工科技评论》中文网是《麻省理工科技评论》在中国唯一官方授权网站。</div>
                            <div>如果您是技术型创业公司、如果您有颠覆性的科技产品，欢迎联系我们：mkt@mittrchina.com，请尽可能详细介绍您的公司以及产品并留下联系方式。</div>
                        </div>
                    </div>
                </Skeleton>
            </div>
        )
    }
}

export default Author