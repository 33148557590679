import React from 'react'
import 'styles/h5share/agree.scss'

const AboutUs = () => {
    return <section className='h5agree aboutus'>
        {/* <div className="title">关于我们</div> */}
        <img src={require('assets/images/appimg/logo.png')} alt="" className='logo'/>
        <div className="title">麻省理工科技评论</div>
        <div className="intro">MIT Technology Review隶属于麻省理工学院新闻办公室，依托麻省理工雄厚的 学术和产业资源，于1899年在美国麻省理工学院创刊， 是世界上历史最悠久，也是影响力最大的技术商业类杂志。</div>
        <div className="bor"></div>
        <img src={require('assets/images/appimg/logo2.png')} alt="" className='logo2'/>
        <div className="title title2">DeepTech深科技</div>
        <div className="intro">是一家专注于新兴科技的内容、文化和服务独立品牌，同时也是麻省理工科技评论（MIT Technology Review）中国大陆地区官方独家合作方。2016年4月正式上线至今，全网订阅量超过400万，月均流量5000-8000万，深受文化、科研、科技产业、投资人士的关注。</div>
    </section>
}
export default AboutUs