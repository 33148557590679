import React, { Component } from 'react'
import 'styles/author/authordetail.scss'
import Subscribe from 'comps/subscribe/Subscribe'
import {withRouter} from 'react-router-dom'
import { Skeleton } from 'antd'
import fromatTime from 'utils/formatTime'
import Auther from 'comps/Auther'
import Avatar from 'comps/Avatar'
interface stateType {
    id:string,
    autherDetail:object,
    params: {
        page:number,
        limit:number,
        author: string,
        type: string,
        label: string,
        is_ad: boolean
    },
    informationList: any[],
    total: number,
    listLoad: boolean
}
interface propsType {
    props:{
        match:any
    }
}

class AuthorDetail extends Component<propsType, stateType> {
    constructor(props) {
        super(props)
        this.state = {
            id: this.props.match.params.id,
            autherDetail: {},
            params:{
                page:1,
                limit:10,
                author: this.props.match.params.id,
                type: '',
                label: '',
                is_ad: false
            },
            informationList: [],
            total: 0,
            listLoad: true
        }
    }

    componentDidMount(){
        this.getAutherDetail()
        this.getInformationList()
    }

    //作者详情
    getAutherDetail = () => {
        let params = {id: this.state.id}
        this.axios.get('/author/details',{params}).then(res => {
            if (res.code === 10000){
                let autherDetail = res.data
                this.setState({
                    autherDetail
                })
            }
        })
    }

    //获取资讯列表
    getInformationList = () => {
        let params =  Object.assign({}, this.state.params, { is_ad: true })
        let informationList = this.state.informationList
        this.axios.get('/information/index', {params}).then(res => {
            if (res.code === 10000) {
                let items = res.data.items
                informationList.push(...items)
                this.setState({
                    informationList,
                    total: res.data.total,
                    listLoad: false
                })
            }
        })
    }


    //加载更多
    loadMore = () => {
        let page = this.state.params.page
        page ++
        let params = Object.assign({}, this.state.params, { page })
        this.setState({params}, () => {
            this.getInformationList()
        })
    }
    render() {
        const { autherDetail, informationList, total, listLoad } = this.state
        return (
            <div className="author-detail">
                <div className="author-detail-left">
                    <div className="author-intro">
                        <Avatar photoUrl={autherDetail.avatar} photoWidth={'120px'} photoHeight={'120px'} isRadius={true}></Avatar>
                        {/* <img src={autherDetail.avatar} alt="" className="subscribeImg" /> */}
                        <div className="author-info">
                            <div className="name">
                                <span>{autherDetail.username}</span>
                                <span>{autherDetail.job}</span>
                            </div>
                            <div className="intro">{autherDetail.summary}</div>
                        </div>
                    </div>
                    <div className="author-article-count">{total}篇文章</div>
                    <Skeleton active loading={listLoad}>
                        <div className="author-body">
                            {
                                informationList.length ? informationList.map(item => {
                                    return <a href={`/news/detail/${item.id}`}>
                                        <div className="article-item">
                                            <div className="article-type">
                                                <span>{item.typeName}</span>
                                                <span>{fromatTime(item.start_time * 1000)}</span>
                                            </div>
                                            <div className="article-title">{item.name}</div>
                                            <div className="article-intro" title={item.summary}>{item.summary}</div>
                                        </div>
                                    </a>
                                }) : <img src={require('assets/images/null-img.png')} alt="" className="nullImg"/>
                            }
                        </div>
                        {
                            total > informationList.length ? <div className="loadMore" onClick={this.loadMore}>加载更多</div> : null
                        }
                    </Skeleton>
                    
                </div>
                <div className="author-detail-right">
                    <Subscribe />
                    <div className="we-team">
                        <div className="we-team-title">
                            <span>我们的团队</span>
                            <a href="/about">查看更多</a> 
                        </div>
                        <Auther></Auther>
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default withRouter(AuthorDetail)