
import React, { Component } from 'react'
import 'styles/components/searchItem.scss'
// import Avatar from 'comps/Avatar'
import fromatTime from 'utils/formatTime'
import { Skeleton } from 'antd'
interface stateType{
    load: boolean
}
interface propsType{
    dataList: any,
    type: string,
    emtechColumn: Boolean
}

class SearchItem extends Component<propsType, stateType> {
    constructor(props: propsType) {
        super(props)
        this.state = {
            load: false
        }
    }
    
    componentDidMount(){

    }

    loadSuccess = () => {
        this.setState({
            load: true
        })
    }

    render() {
        const {dataList, type, emtechColumn} = this.props
        const {load} = this.state
        return (
            <a href={`/${type}/detail/${dataList.id}`}>
                <dl className="searchItem">
                    {
                        type === 'video' ? <dd>
                            <div>
                                {/* <div className="type">{dataList.typeName}</div> */}
                                <h1 title={dataList.name || dataList.title}>{dataList.name || dataList.title}</h1>
                                {/* <div className="con">{dataList.summary}</div> */}
                            </div>
                            <div className="date">
                                {/* <span>{dataList.authors ? dataList.authors[0].username : ''}</span> */}
                                <span>{fromatTime(dataList.start_time * 1000)}</span>
                            </div>
                        </dd> : <dd>
                            <div>
                                {emtechColumn ? null : <div className="type">{dataList.typeName}</div>}
                                <h1 title={dataList.name || dataList.title}>{dataList.name || dataList.title}</h1>
                                <div className="con">{dataList.summary}</div>
                            </div>
                            <div className="date">
                                <span>{dataList.authors ? dataList.authors[0].username : ''}</span>
                                <span>{fromatTime(dataList.start_time * 1000)}</span>
                            </div>
                        </dd>
                    }
                    
                    <dt>
                        {/* <Avatar photoUrl={dataList.cover || dataList.img} photoWidth={'296px'} photoHeight={'178px'} isRadius={false}></Avatar> */}
                        {(dataList.cover && <img src={dataList.cover + '?imageView2/2/w/296/h/178'} alt="" className="defaultImg"  onLoad={this.loadSuccess}/>) || 
                        (dataList.img && <img src={dataList.img + '?imageView2/2/w/296/h/178'} alt="" className="defaultImg"  onLoad={this.loadSuccess}/>)
                        }
                        {
                            !load ? <Skeleton active className="loadingImg"/> : null
                        }
                    </dt>
                </dl>
            </a>
        )
    }
}
export default SearchItem

